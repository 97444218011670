import React from "react"
import { graphql } from "gatsby"
import tw, { styled } from "twin.macro"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section/section"

import ProductSummary from "../components/guide/product-summary"
import ProductSpecifications from "../components/guide/product-specifications"
import FullsizeImage from "../components/utilities/fullsize-image"

const ArticleWrap = styled.div`
    ${tw`container flex flex-col items-center`}
`

const ArticleContent = styled.article`
    ${tw`max-w-4xl`}
`

const Description = styled.p`
    ${tw`leading-relaxed my-3 font-medium`}
`

const Separator = styled.hr`
    ${tw`w-24 rounded-md border-2 border-psb-orange-400`}
`

const Content = styled.div`
    ${tw`mt-4`}
`

const ProductTemplate = ({ data, location }) => {
    const product = data.product
    const today = new Date()
    const date = today.getDate() + ' ' + today.toLocaleString('default', { month: 'long' }) + ', ' + today.getFullYear()

    return (
        <Layout>
            <SEO
                title={product.name}
                description={product.summary}
                image=""
                pathname={location.pathname}
            />
            <Hero date={date} title={product.name} description="" />
            <Section>
			    <ArticleWrap>
                    <ArticleContent>
                        <FullsizeImage filename={product.fullsizeImage} alt={product.name} />
                        <figcaption><strong>{product.name}</strong>&emsp;(Source: {product.imageAttribution})</figcaption>
                        <Description>{product.summary}</Description>
                        <Separator />
                        <Content>
                            <h2>Summary</h2>
                            <ProductSummary
                                name={product.name}
                                slug={"/guides/" + product.productType + "/" + product.slug}
                                thumbnail={product.thumbnailImage}
                                merchantURL={product.merchantURL}
                                merchant={product.merchant}
                                summary={product.summary}
                                score={product.score}
                                criteriaScoreArray={product.criteriaScoreArray}
                                prosArray={product.prosArray}
                                consArray={product.consArray}
                            />
                            <h2>Specifications</h2>
                            <ProductSpecifications specifications = {product.specificationsArray} />
                        </Content>
                    </ArticleContent>
			    </ArticleWrap>
            </Section>
        </Layout>
    )
}

export default ProductTemplate

export const query = graphql`
    query($slug: String!) {
        product: productsJson( slug: { eq: $slug } ) {
            productType
            name
            slug
            thumbnailImage
            fullsizeImage
            imageAttribution
            merchantURL
            merchant
            summary
            score
            criteriaScoreArray {
                name
                score
            }
            categories
            prosArray
            consArray
            specificationsArray {
                groupName
                attributesArray {
                    name
                    value
                    highlight
                }
            }
        }
    }
`