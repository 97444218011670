import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const FullsizeImage = ({ filename, alt }) => {

	return (
		<StaticQuery
            query={graphql`
                query {
                    images: allFile (filter: { sourceInstanceName: { eq: "fullsize" } })
                    {
                        edges {
                            node {
                                relativePath
                                name
                                childImageSharp {
                                    fluid(maxWidth: 1200 quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) => {
                const image = data.images.edges.find(n => { return n.node.relativePath.includes(filename) })
                if (!image) { return null }
                return <Img alt={alt} fluid={image.node.childImageSharp.fluid} />
                }
            }
        />
	)
}

export default FullsizeImage